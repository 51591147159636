import React, { useState } from "react"
import cx from "classnames"
import "./Burger.scss"

export default ({ onClick }) => {
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
    onClick()
  }

  return (
    <button onClick={handleClick} className={cx("burger", { active })}>
      <i></i>
    </button>
  )
}
