import React, { useState, useEffect } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import FrameSvg from "../../assets/svg/frame.svg"
import Logo from "../../assets/svg/logo.svg"
import FrameSvg2 from "../../assets/svg/nae-logo.svg"
import Logo2 from "../../assets/svg/nae-logo.svg"
import Burger from "../Burger"
import "./Header.scss"

export default ({ compact }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const MENU = [
    {
      title: "Apie Projektą",
      href: "/about",
      target: ""
    },
    {
      title: "Pasiruošk",
      href: "/education",
      target: ""
    },
    {
      title: "D.U.K",
      href: "/duk",
      target: ""
    },

  ]

  useEffect(() => {
    const onScroll = e => {
      if (isScrolled && window.scrollY === 0) {
        setIsScrolled(false)
      }

      if (!isScrolled && window.scrollY > 0) {
        setIsScrolled(true)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [isScrolled])

  return (
    <header className={cx("header", { compact, scrolled: isScrolled })}>
      <div className="container">
        {compact && (
          <div className="tac">
            <img height="44px" src={Logo2} alt="" />
          </div>
        )}

        {!compact && (
          <>
            <Link
              onClick={() => {
                document.body.classList.remove("burger-open")
              }}
              to="/"
            >
              <img src={FrameSvg2} alt="" />
            </Link>
            <nav className={cx({ active: isMenuOpen })}>
              <ul>
                {MENU.map((item, index) => (
                  <li
                    className={cx({ "dn-mobile-up": item.mobile })}
                    key={`menu-${index}`}
                  >
                    <Link
                      onClick={() => {
                        document.body.classList.remove("burger-open")
                      }}
                      to={item.href}
                      title={item.title}
                      target={item.target}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            <Burger
              onClick={() => {
                if (!isMenuOpen) {
                  document.body.classList.add("burger-open")
                } else {
                  document.body.classList.remove("burger-open")
                }

                setIsMenuOpen(!isMenuOpen)
              }}
            />
          </>
        )}
      </div>
    </header>
  )
}
