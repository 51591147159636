import React from "react"
import { Link } from "gatsby"
import "./Footer.scss"
import LrtLogo from "../../assets/svg/lrt-logo-nae.svg"

import IgnitisLogo from "../../assets/svg/igng.svg"
import EcoLogo from "../../assets/svg/ecococon.svg"
import NetherlandsLogo from "../../assets/svg/NLnew.svg"
import ZaliasisLogo from "../../assets/svg/zaliasis-taskas.svg"
import NGLogo from "../../assets/svg/NG-LT-logo.svg"

import Klaipeda from "../../assets/images/png/klaipedos.png"
import Vilnius from "../../assets/images/png/vilnius.png"

import Continental from "../../assets/images/png/Continental.png"
import AplinkosMin from "../../assets/svg/aplinkos-ministerija.svg"

import VSTT from "../../assets/images/png/VSTT.png"
import Vilniausrajonas from "../../assets/images/png/vilniausrajono.png"
import URM from "../../assets/images/png/urm.png"
import Utenos from "../../assets/images/png/utenos.png"
import Sustain from "../../assets/images/png/sustainacademy.png"
import Solidu from "../../assets/images/png/Solidu.png"
import SDG from "../../assets/images/png/SDG.png"
import LTaid from "../../assets/images/png/ltaid.png"
import Ekobaze from "../../assets/images/png/Ekobaze.png"

import AugaLogo from "../../assets/images/png/auga_logo.png"

import ZaliosiosPolitikosLogo from "../../assets/images/png/ZaliosiosPolInst.png"
import AplinkosaugosLogo from "../../assets/images/png/am-logo.png"
import Audioteka from "../../assets/images/png/audioteka.png"

import margaritaLogo from "../../assets/images/png/margarita.png"
import smsmLogo from "../../assets/images/png/smsm.png"
import teliaLogo from "../../assets/images/png/telia.png"
import erasmusLogo from "../../assets/images/png/erasmusplus.png"

import FbLogo from "../../assets/svg/nae-facebook.svg"
import InstaLogo from "../../assets/svg/nae-instagram.svg"

import LongArrowRight from "../../assets/svg/long-arrow-right.svg"


export default () => (
  <footer className="footer">
    <div className="container">

      <div style={{display: "inline-flex", flexFlow: "wrap", justifyContent: "center", alignContent:"space-around"}}>

      <div className="footer--organisators" style={{maxWidth:"260px"}}>
        <div className="footerOrganiserTitleH">Projekto organizatorius</div>

        <div className="footer--organisators" style={{display: "flex", flexWrap:"wrap", rowGap:15, marginTop: 0}} >
      <div>
        <a
          href="http://zaliojipolitika.lt/"
          rel="noopener noreferrer"
          target="_blank"
          title="Žaliosios politikos institutas"
        >
          <img height="79px" src={ZaliosiosPolitikosLogo} alt="Žaliosios politikos institutas" />
        </a>
        {/* <div className="footerOrganiserTitle">Žaliosios politikos institutas</div> */}
      </div>
        </div>
      </div>

        <div className="footer--organisators" style={{maxWidth:"260px"}}>
          <div className="footerOrganiserTitleH">Projekto informacinis partneris</div>
          <div className="footer--organisators" style={{display: "flex", flexWrap:"wrap", rowGap:15, marginTop: 0}} >
            <div>
              <a
                href="https://lrt.lt"
                rel="noopener noreferrer"
                target="_blank"
                title="LRT"
              >
                <img height="90px" src={LrtLogo} alt="LRT" />
              </a>
              {/*<div className="footerOrganiserTitle">Lietuvos nacionalinis radijas ir televizija</div>*/}
            </div>
          </div>
        </div>

        <div className="footer--organisators" style={{maxWidth:"260px"}}>
          <div className="footerOrganiserTitleH">Projekto globėjas</div>
          <a
            href="https://am.lrv.lt/"
            rel="noopener noreferrer"
            target="_blank"
            title="Lietuvos Respublikos aplinkos ministerija"
          >
            <img src={AplinkosMin} alt="Lietuvos Respublikos aplinkos ministerija" style={{maxWidth:120}} />
          </a>
          {/* <div className="footerOrganiserTitle">Lietuvos Respublikos aplinkos ministerija</div>*/}
        </div>


        {
          /*

          <div className="footer--organisators" style={{maxWidth:"256px"}}>
        <div className="footerOrganiserTitleH">Pagrindinis rėmėjas</div>
        <a
          href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/lithuania/"
          rel="noopener noreferrer"
          target="_blank"
          title="Nyderlandų Karalystės ambasada Lietuvoje"
        >
          <img height="72px" src={NetherlandsLogo} alt="Nyderlandų Karalystės ambasada Lietuvoje" />
        </a>
        <div className="footerOrganiserTitle">Nyderlandų Karalystės ambasada Lietuvoje</div>
      </div>

          */
        }


      </div>




      <div style={{display: "inline-flex", flexFlow: "wrap", justifyContent: "center"}}>


        <div style={{marginTop: 25}}>
          <div className="footerOrganiserTitleH">Projekto partneriai</div>
          <div className="footer--organisators" style={{display: "flex", flexWrap:"wrap", rowGap:15, marginTop: 0}} >
            <a
              href="https://urm.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Užsienio reikalų ministerija"
            >
              <img src={URM} alt="" style={{width:100}} />
            </a>
            <a
              href="https://ltaid.urm.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="LT Aid"
            >
              <img src={LTaid} alt="" style={{width:90}} />
            </a>
            <a
              href="https://vilnius.lt/lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Vilniaus miesto savivaldybė"
            >
              <img src={Vilnius} alt="Vilniaus miesto savivaldybė" style={{width:70}}/>
            </a>
            <a
              href="https://www.vrsa.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Vilniaus rajono savivaldybė"
            >
              <img src={Vilniausrajonas} alt="" style={{width:100}}/>
            </a>
            <a
              href="https://utena.lt/lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Utenos rajono savivaldybė"
            >
              <img src={Utenos} alt="" style={{width:55}}/>
            </a>
            <a
              href="https://www.zaliasistaskas.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Žaliasis taškas"
            >
              <img src={ZaliasisLogo} alt="Žaliasis taškas" style={{width:120}}/>
            </a>
            <a
              href="https://www.continental.com/en/kaunas-lithuania/"
              rel="noopener noreferrer"
              target="_blank"
              title="Continental"
            >
              <img src={Continental} alt="" style={{width:120}}/>
            </a>
            <a
              href="https://www.sustainacademy.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Sustain Academy"
            >
              <img src={Sustain} alt="" style={{width:135}}/>
            </a>

          </div>
        </div>




      </div>


      <div style={{display: "inline-flex", flexFlow: "wrap", justifyContent: "center"}}>


        <div style={{marginTop: 25}}>
          <div className="footerOrganiserTitleH">Projekto draugai</div>
          <div className="footer--organisators" style={{display: "flex", flexWrap:"wrap", rowGap:15, marginTop: 0}} >
            <a
              href="https://www.nationalgeographic.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="National Geographic Lietuva"
            >
              <img src={NGLogo} alt="National Geographic Lietuva" style={{width:130}}/>
            </a>
            <a
              href="https://audioteka.com/lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Audioteka"
            >
              <img src={Audioteka} alt="Audioteka" style={{width:125}}/>
            </a>
            <a
              href="https://www.sdg.lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="SDG"
            >
              <img src={SDG} alt="" style={{width:80}}/>
            </a>
            <a
              href="https://soliducosmetics.com/lt/"
              rel="noopener noreferrer"
              target="_blank"
              title="Solidu"
            >
              <img src={Solidu} alt="" style={{width:70}}/>
            </a>
            <a
              href="https://www.ekobaze.eu/ "
              rel="noopener noreferrer"
              target="_blank"
              title="Ekobazė"
            >
              <img src={Ekobaze} alt="" style={{width:90}}/>
            </a>


          </div>
        </div>




      </div>
      {/*
      <div className="footer--socials">
        <img src={LongArrowRight} alt="" />

        <a
          href="https://www.facebook.com/events/772006927029270/"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <img src={FbLogo} alt="Facebook" />
        </a>
      </div>
      */}

      {/*<div className="dn-mobile">*/}
      {/*  <Link to="/privacy-policy" title="Privatumo politika">*/}
      {/*    Privatumo politika*/}
      {/*  </Link>*/}
      {/*</div>*/}


    </div>

    <div className="footerMini ">

      <div className="footerMini--socials" style={{maxWidth:"1120px", margin:"auto"}}>

      <div style={{fontSize: "14px"}} className="trademarkFooter">


        <div style={{marginLeft:"8px"}}>
          © Žaliosios Politikos Institutas, 2023
        </div>

        <div className="pipeFooter" style={{marginLeft:"8px"}}>
          {" "}|
        </div>

        <div style={{marginLeft:"8px"}}>
          {" "}
          <Link to="/privacy-policy" title="Privatumo politika">
            Privatumo politika
          </Link>
        </div>
      </div>

      <div>

        <a
          href="https://www.instagram.com/aplinkosaugosegzaminas/"
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
          style={{paddingLeft:"16px"}}
        >
          <img src={InstaLogo} alt="Instagram" style={{width:24}}/>
        </a>

        <a
          href="https://www.facebook.com/Aplinkosaugosegzaminas"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <img src={FbLogo} alt="Facebook" style={{width:24}}/>
        </a>

      </div>
      </div>



    </div>
  </footer>
)
